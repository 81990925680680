import React from "react";
import BaseSelect, { IBaseSelectProps } from '../select';

export default class GenderInput extends React.Component<IBaseSelectProps> {
    static defaultProps: IBaseSelectProps;

    options = [
        { value: "F", label: "Feminino" },
        { value: "M", label: "Masculino" }
    ]

    render() {
        return (
            <BaseSelect
                {...this.props}
                label={this.props.label || "Sexo"}
                type="string"
                options={this.options}
            />
        );
    }
}   