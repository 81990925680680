import React from "react";
import BaseCheckboxRadioSwitch, { IBaseCheckboxRadioSwitchProps } from "../../input/checkbox-radio-switch";

export interface ICheckboxInputGroupProps {
    elements: IBaseCheckboxRadioSwitchProps[],
    label: string
}

class CheckboxInputGroup extends React.Component<ICheckboxInputGroupProps> {
    render() {
        return this.props.elements.map(value => (
                    <BaseCheckboxRadioSwitch 
                        {...value}
                        groupLabel={this.props.label}
                        inline
                    />
                ));
    }
}

export default CheckboxInputGroup;