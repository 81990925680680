import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.scss';

export enum TypesBaseModal {
    create = "Novo",
    read = "Detalhar",
    update = "Editar",
    delete = "Excluir",
    search = "Pesquisar",
    log = "Log",
    export = "Exportar"
}

export type IBaseModalProps = {
    content: object | undefined,
    type?: TypesBaseModal,
    show: boolean,
    size?: "sm" | "lg" | "xl"
    callbackAfterClose(): void | undefined,
    data?: Array<any>,
    name: string
} & Partial<DefaultProps>

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
    show: false
}

interface IBaseModalState {
    show: boolean,
    loading: boolean
}

export default class BaseModal extends React.Component<IBaseModalProps, IBaseModalState> {
    public static defaultProps = defaultProps;

    state: IBaseModalState = {
        show: this.props.show,
        loading: false
    }

    handleClose = () => {
        this.setState(() => ({
            show: false
        }), () => {
            this.props.callbackAfterClose();
        });
    }

    render() {
        return (
            <>
                <Modal backdrop="static" keyboard={false} show={this.props.show} onHide={this.handleClose} size={this.props.size}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.type}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {React.cloneElement(this.props.content as ReactElement, { data: this.props.data, name: this.props.name })}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={this.handleClose}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}