import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";

import BaseService from "./services/base-service";
export { default as BaseService } from "./services/base-service";
export { default as AuthService } from "./services/auth-service";
export { default as LogService } from "./services/log-service";
export { default as PermissionService } from "./services/permission-service";
export { default as ZipCodeService } from "./services/zipcode-service";

import {
  IDataType as _IDataType,
  IError as _IError,
  IMessage as _IMessage,
  IResponse as _IResponse
} from "./base/IResponse";

export * from "./base";
export * from "./base/input/input";
export * from "./base/input/base";
export * from "./base/input/cep";
export { default as BaseCheckboxRadioSwitch } from "./base/input/checkbox-radio-switch";
export * from "./base/input/cnpj";
export * from "./base/input/cpf";
export * from "./base/input/date";
export * from "./base/input/date-range";
export * from "./base/input/gender";
export * from "./base/input/input";
export * from "./base/input/phone";
export * from "./base/input/select";
export * from "./base/input/state";
export * from "./base/context/user";
export * from "./base/error-boundary";
export * from "./base/export";
export * from "./base/fieldset";
export * from "./base/form";
export * from "./base/grid";
export * from "./base/input-group/address";
export * from "./base/input-group/bank";
export * from "./base/input-group/checkbox";
export * from "./base/log";
export * from "./base/modal";
export * from "./base/nav-bar";
export * from "./base/nav-tabs";
export * from "./base/pagination";
export * from "./base/screen";
export * from "./base/toolbar";
export * from "./base/util";

export type IDataType = _IDataType;
export type IError = _IError;
export type IMessage = _IMessage;
export type IResponse = _IResponse;
