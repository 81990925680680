import React from 'react'
import { Alert, Container, Button } from 'react-bootstrap'

export default class ErrorBoundary extends React.Component {
    state = { 
        hasError: false,
        error: undefined as any
    };
    
    static getDerivedStateFromError(error: any) {
      return { 
          hasError: true,
          error
        };
    }

    closeError = () => {
      this.setState({
        hasError: false,
        error: undefined
      });
    }
  
    render() {      
      if (this.state.hasError) {
          return (
            <Container className='mt-2' fluid>
              <Alert variant='danger' onClose={this.closeError} dismissible>
                <Alert.Heading>Desculpe, você recebeu um erro!</Alert.Heading>
                  <p>
                    Nós recebemos a notificação do erro, mas pedimos que mesmo assim, você abra um chamando informando o ocorrido.
                    Muito obrigado pela compreensão.
                  </p>
                  
                  <hr />

                  <Alert.Heading>Detalhe:</Alert.Heading>
                  <p>{this.state.error.message}</p>
                  <p>{this.state.error.stack}</p>

                  <hr />

                  <div className='text-right'>
                    <Button onClick={this.closeError} variant='outline-danger'>Fechar</Button>
                  </div>
              </Alert>
            </Container>
          );     
      }
      
      return this.props.children; 
    }
  }