import React, { ReactNode, Fragment, ReactElement } from 'react'
import { Nav } from 'react-bootstrap'

type BaseNavTabsProps = {
    navs: {
        key: string,
        name: string,
        content: ReactNode,
        hidden?: boolean,
        disabled?: boolean
    }[],
    defaultActiveKey: string,
    data: any[],
    parentPropertyName?: string,
    childPropertyName?: string
    onSelect?(key: string): void
}

class BaseNavTabs extends React.Component<BaseNavTabsProps, { selectedItem: string}> {

    state = {
        selectedItem: this.props.defaultActiveKey
    }

    onSelect = (selectedItem: string) => {
        const { onSelect } = this.props;
        
        this.setState({
            selectedItem
        }, () => {
            if (onSelect) onSelect(selectedItem);
        });
    }

    displayOrNot = (key: string) : string => {
        return this.state.selectedItem === key ? 'block' : 'none';
    }

    render() {
        const { navs, defaultActiveKey, data } = this.props;

        return (
            <Fragment>
                <Nav variant="tabs" defaultActiveKey={defaultActiveKey} onSelect={this.onSelect}>
                    {navs.map((value, i) => (
                        <Nav.Item key={i} hidden={value.hidden}>
                            <Nav.Link eventKey={value.key} disabled={value.disabled != null ? value.disabled :  value.disabled || this.state.selectedItem !== value.key && data.length !== 1}>{value.name}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                {navs.map((value, i) => (
                    <div key={i} style={{display: this.displayOrNot(value.key)}}>
                        {React.cloneElement(
                            value.content as ReactElement, 
                            {
                                load: this.state.selectedItem === value.key,
                                filter: [{
                                    operator: "=",
                                    property: this.props.childPropertyName || 'id',
                                    value: this.props.data.map(item => item[this.props.parentPropertyName || 'id']),
                                    closeable: false
                                }],
                                dependentProperty: {
                                    name: this.props.childPropertyName || 'id',
                                    value: this.props.data.map(item => item[this.props.parentPropertyName || 'id'])[0]
                                }
                            }
                        )}
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default BaseNavTabs;