import React, { RefObject } from 'react';
import { Col } from 'react-bootstrap';
import { faCalendarAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import './style.scss';

import ptBR from 'date-fns/locale/pt-BR';
import BaseInput from '../input';
registerLocale('ptBR', ptBR)

export interface IDateInputProps {
    label: string,
    name: string,
    col?: {
        xs?: number,
        sm?: number,
        md?: number,
        lg?: number,
        xl?: number
    },
    id: string,
    selected?: Date | null,
    startDate?: Date | null,
    endDate?: Date | null,
    minDate?: Date | null,
    maxDate?: Date | null,
    selectsStart?: boolean,
    selectsEnd?: boolean,
    required?: boolean,
    callbackChange?(date: Date | null): void | undefined,
    innerRef?: RefObject<BaseInput>
}

export default class DateInput extends React.Component<IDateInputProps> {
    static defaultProps: IDateInputProps;
    state = {
        date: null
    };
     
    onChange = (event: React.FocusEvent<HTMLInputElement>)=>{
    }
 
    handleFocus = (event: React.FocusEvent<HTMLInputElement>)=>{
    }

    reset = () => {
        this.handleChange(null);
    }

    handleChange = (date: Date | null) => {
        let instance = this.props.innerRef;
        if (instance && instance.current) {
            if (date) {
                instance.current.setValue(date);
                instance.current.setIsInvalid(false);
            }
            else {
                instance.current.setValue('');
            }
        }
 
        this.setState(() => ({ date }), () => {
            this.props.callbackChange && this.props.callbackChange(date)
        } );
    }

    onBlur = () => {
        let date = this.state.date;

        if (this.state.date && this.props.minDate)
            if (this.state.date! < this.props.minDate!) date = null;

        if (this.state.date && this.props.maxDate)
            if (this.state.date! > this.props.maxDate!) date = null;

        if (!date) {
            let instance = this.props.innerRef;

            if (instance && instance.current)
                instance.current.setValue(date)
                
            this.setState(() => ({
                date
            }), () => this.props.callbackChange && this.props.callbackChange(date) );
        }
    }

    render() {
        const { col } = this.props;

        return (
            <Col xs={col!.xs} sm={col!.sm} md={col!.md} lg={col!.lg} xl={col!.xl}>
                <DatePicker 
                    {...this.props}
                    selected={this.state.date}
                    onChange={this.handleChange} 
                    onBlur={this.onBlur}
                    onFocus={this.handleFocus}
                    dateFormat="dd/MM/yyyy"
                    locale="ptBR" 
                    customInput={
                        <CustomDateInput 
                            id={this.props.id}
                            name={this.props.name}
                            label={this.props.label}
                            onRemoveClick={()=> this.handleChange(null)}
                            onChange={this.onChange}
                            onFocus={this.handleFocus}
                            innerRef={this.props.innerRef}
                        />
                    }
                />
            </Col>
        );
    }
}

DateInput.defaultProps = {
    label: "Data",
    name: "date",
    id: "bababa",
    col: {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 6
    },
    minDate: null
}

interface ICustomDateInput {
    name: string,
    id: string,
    label: string,
    onClick?(): void,
    onRemoveClick?(event: any): void,
    onChange(event:  React.FocusEvent<HTMLInputElement>): void | undefined
    onFocus?(event:  React.FocusEvent<HTMLInputElement>): void,
    innerRef?: RefObject<BaseInput>
}

export class CustomDateInput extends React.Component<ICustomDateInput> {
    render() {
        return (
            <BaseInput
                {...this.props}
                type="text"
                onClick={this.props.onClick}
                onFocus={this.props.onFocus}
                onChange={this.props.onChange}
                autoComplete="off"
                inputGroup={{
                    prepend: {
                        onClick: this.props.onClick,
                        buttonProps: {
                            variant: "primary"
                        },
                        icon: faCalendarAlt
                    },
                    append: {
                        onClick: this.props.onRemoveClick,
                        buttonProps: {
                            variant: "secondary"
                        },
                        icon: faTrash
                    }
                }}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                ref={this.props.innerRef}
                isDate    
            />
        );
    }
}