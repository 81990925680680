import React from "react";
import { Form, FormCheckProps, InputGroup } from "react-bootstrap";
import { ICommonInputProps, REQUIRED_MESSAGE } from "../base";

export interface IBaseCheckboxRadioSwitchProps extends ICommonInputProps {
    type: FormCheckProps["type"],
    checked?: boolean,
    inline?: boolean,
    groupLabel?: string
}

interface IBaseCheckboxRadioSwitchState {
    value: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    hidden?: boolean,
    isValid?: boolean,
    isInvalid?: boolean
}

class BaseCheckboxRadioSwitch extends React.Component<IBaseCheckboxRadioSwitchProps, IBaseCheckboxRadioSwitchState> {
    state: IBaseCheckboxRadioSwitchState = {
        value: false,
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    componentDidMount() {
        if (this.props.checked)
            this.setValue(this.props.checked);
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.checked;

        this.setState({
            isInvalid: (this.props.required && !value) ? true : false,
            value
        });

        if(this.props.onChange) this.props.onChange(event);
    }

    reset = () => {
        this.setState({
            value: this.props.checked || false,
            isInvalid: false,
            isValid: false
        });
    }

    setValue = (value: boolean) => this.setState({ value });
    getValue = () => this.state.value;
    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });


    checkIfHasInvalidFeedback = () => {
        const { props, state} = this;

        if (!state.isInvalid) return null;

        let feedback = '';
        if (props.required && !state.value)
            feedback = REQUIRED_MESSAGE;
        else if (props.feedback && props.feedback.invalid)
            feedback = props.feedback.invalid;
        else 
            return null;
       
        return feedback;
    }

    render() {
        const { props, state, onChange, checkIfHasInvalidFeedback } = this;
          
        const label = props.required ? <>
            {props.label} <span className="text-danger">
                                <strong>*</strong>
                            </span></> : props.label;

        return <Form.Group hidden={props.hidden}>
                    <InputGroup>
                        {props.groupLabel && 
                        <Form.Label 
                            htmlFor={props.id}>
                                {props.label} 
                                {props.required && 
                                    <span className="text-danger">&nbsp;
                                    <strong>*</strong>
                                </span>}
                        </Form.Label>}
                        <Form.Check
                            {...props}
                            custom
                            id={props.id || `checkbox-radio-switch-${props.name}`}

                            readOnly={state.readOnly}
                            disabled={state.disabled}
                            isValid={state.isValid}
                            isInvalid={state.isInvalid}
                            checked={state.value}

                            label={label}
                            onChange={this.onChange}
                            feedback={checkIfHasInvalidFeedback()}
                        />
                            
                        
                    </InputGroup>
                </Form.Group>;
    }
}

export default BaseCheckboxRadioSwitch;