import BaseService from './base-service'

const baseEndpoint: string = window.moveUrl;
const baseService = new BaseService();
baseService.setBaseEndpoint(baseEndpoint);

class PermissionService { 
    static setBaseEndpoint = (endpoint: string) => {
        baseService.setBaseEndpoint(endpoint);
    }
    
    static get = (userId: number, moduleId: number) => {
        return baseService.get(`/ePharmaAPI/Api/Permissao/ObterPermissoesDeAcao?IdUsuario=${userId}&IdModulo=${moduleId}`);
    }
}

export default PermissionService;