import React from "react";
import { Form, InputGroup, ButtonProps, Button, Spinner } from "react-bootstrap";
import { ICommonInputProps, REQUIRED_MESSAGE } from "../base";
import MaskedInput from "react-text-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Util from "../../util";
import { isDate } from "util";
import './style.scss'

export interface IBaseInputProps extends ICommonInputProps {
    type?: string,
    as?: "input" | "textarea",
    isDate?: boolean,
    onFocus?: ((event: any) => void),
    onChange?: ((event: any) => void),
    onBlur?: ((event: any) => void),
    onClick?: ((event: any) => void),
    reset?:((event: any) => void),
    value?: any,
    mask?: Array<any> | ((value: string) => Array<any>) | undefined,
    notAllowSpecialCharacters?: boolean,
    transformCase?: 'upper' | 'lower',
    maxLength?: number,
    autoComplete?: string,
    identifier?: boolean,
    inputGroup?: {
        prepend?: {
            icon: any,
            buttonProps: ButtonProps,
            onClick?(event: any): Promise<void> | void | undefined
        },
        append?: {
            icon: any,
            buttonProps: ButtonProps,
            onClick?(event: any): Promise<void> | void | undefined
        }
    }
}

interface IBaseInputState {
    value: any,
    loading?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    hidden?: boolean,
    isValid?: boolean,
    isInvalid?: boolean
}

class BaseInput extends React.Component<IBaseInputProps, IBaseInputState> {
    static defaultProps: Partial<IBaseInputProps>;

    state: IBaseInputState = {
        value: this.props.defaultValue,
        loading: this.props.loading,
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    static getDerivedStateFromProps(props: IBaseInputProps, state: IBaseInputState) {
        if (props.loading != state.loading)
            return { loading: props.loading };
        
        return null;
    }

    componentDidMount() {
        if (this.props.value)
            this.setValue(this.props.value);
    }

    onChange = (event: any) => {
        let value = event.target.value;
        
        if(!this.props.isDate){
            if (this.props.notAllowSpecialCharacters) value = value.replace(/[^\w ]+/g, '');

            if (this.props.transformCase) {
                if (this.props.transformCase === 'upper') value = value.toUpperCase();
                else if (this.props.transformCase === 'lower') value = value.toLowerCase();
            }
        }
        this.setValue(value);
        if(this.props.onChange) this.props.onChange(event);
    }

    onBlur = (event: any) => {
        let value = event.target.value;

        if (this.props.required && value) {
            this.setState({
                isInvalid: false
            });
        }

        if (this.props.onBlur) this.props.onBlur(event);
    }

    onFocus = (event: any)=>{
        if(this.props.onFocus) this.props.onFocus(event);
    }

    reset = () => {
        if(this.props.isDate) { 
            this.props['onRemoveClick']()
        }
 
        this.setState({
            value: this.props.value || this.props.defaultValue || '',
            isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
            isValid: this.props.isValid ? this.props.isValid : false,
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            hidden: this.props.hidden ? this.props.hidden : false
        });
    }
    setValue = (value: any) => { this.setState({ value });};
    getValue = () => {
        return isDate(this.state.value) ? 
            Util.dateISOToDate(this.state.value) : 
            this.state.value;
    }

    getRawValue = () => this.state.value;

    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });

    checkIfHasInvalidFeedback = () => {
        const { props, state } = this;

        if (!state.isInvalid) return null;

        let feedback = '';
        if (props.required && !state.value)
            feedback = REQUIRED_MESSAGE;
        else if (props.feedback && props.feedback.invalid)
            feedback = props.feedback.invalid;
        else 
            return null;
       
        return <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>;
    }

    checkPrependInputGroup = () => {
        const { inputGroup } = this.props;

        if (this.state.readOnly || this.state.disabled) return;

        if (inputGroup && inputGroup.prepend)
            return (<InputGroup.Prepend className="custom-input mr-0">
                        <Button 
                            size="sm" 
                            onClick={inputGroup.prepend.onClick} 
                            variant={inputGroup.prepend.buttonProps.variant}
                            disabled={inputGroup.prepend.buttonProps.disabled}>
                                <FontAwesomeIcon icon={inputGroup.prepend.icon} />
                        </Button>
                    </InputGroup.Prepend>);
    }

    checkAppendInputGroup = () => {
        const { inputGroup } = this.props;

        if (this.state.readOnly || this.state.disabled) return;

        if (inputGroup && inputGroup.append)
            return (<InputGroup.Append className="custom-input ml-0">
                        <Button 
                            size="sm"
                            onClick={inputGroup.append.onClick} 
                            variant={inputGroup.append.buttonProps.variant}
                            disabled={inputGroup.append.buttonProps.disabled}>
                                <FontAwesomeIcon icon={inputGroup.append.icon} />
                        </Button>
                    </InputGroup.Append>);
    }

    render() {
        const { props, state, onChange, onBlur, onFocus, checkIfHasInvalidFeedback, getValue } = this,
            render = props.mask ?
                <MaskedInput
                    className={`form-control form-control-sm ${state.isValid ? 'is-valid': state.isInvalid ? 'is-invalid' : ''}`}
                    guide={false}

                    readOnly={state.readOnly}
                    disabled={state.disabled}
                    value={(props.isDate ? props.value || getValue()  : getValue()) || props.defaultValue || ''}

                    id={props.id}
                    name={props.name}
                    type={props.type}
                    required={props.required}
                    maxLength={props.maxLength}
                    mask={props.mask}
                    autoComplete={props.autoComplete}
                    onFocus={onFocus}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={props.onClick}
                /> : 
                <Form.Control
                    size="sm"

                    readOnly={state.readOnly}
                    disabled={state.disabled}
                    isValid={state.isValid}
                    isInvalid={state.isInvalid}
                    value={(props.isDate ? props.value || getValue() : getValue()) || props.defaultValue || ''}
                    id={props.id}
                    as={props.as}
                    name={props.name}
                    type={props.type}
                    required={props.required}
                    maxLength={props.maxLength}
                    autoComplete={props.autoComplete}   
                    onFocus={onFocus}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={props.onClick}                 
                />;

        return  <>
                    {state.loading && 
                        <Spinner
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            size="sm"
                            variant="primary"
                            className="spinner-input"
                            hidden={!state.loading}
                        />
                    }
                    <Form.Group hidden={state.hidden}>
                        <Form.Label 
                            htmlFor={props.id}>
                                {props.label} 
                                {props.required && 
                                    <span className="text-danger">&nbsp;
                                    <strong>*</strong>
                                </span>}
                        </Form.Label>
                        <InputGroup>
                            {this.checkPrependInputGroup()}
                            {render}
                            {this.checkAppendInputGroup()}
                            
                            {checkIfHasInvalidFeedback()}

                            {props.feedback && props.feedback.valid && 
                            <Form.Control.Feedback type="valid">{props.feedback.valid}</Form.Control.Feedback>}
                        </InputGroup>
                        {state.value && state.value.length > 0 && props.maxLength && props.maxLength > 1 && 
                            <Form.Text className="text-muted text-right">
                                {state.value.length + " / " + props.maxLength} 
                            </Form.Text>
                        }
                    </Form.Group>
                </>;
    }
}

BaseInput.defaultProps = {
    type: 'text',
    as: 'input'
}

export default BaseInput;