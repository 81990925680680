import Axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
var _token: string = "";

export default class BaseService {
  private _axiosInstance: any = null;
   
  setBaseEndpoint = (baseUrl: string) => {
    this._axiosInstance = Axios.create({
      baseURL: baseUrl
    });
  };

  setToken = (token: string) => {
    _token = token;
  };

  get = async (endpoint: string) => {
    return await this._axiosInstance.get(endpoint);
  };

  post = async (endpoint: string, data: any) => {
    return await this._axiosInstance.post(endpoint, data);
  };

  put = async (endpoint: string, data: any) => {
    return await this._axiosInstance.put(endpoint, data);
  };

  delete = async (endpoint: string) => {
    return await this._axiosInstance.delete(endpoint);
  };
}

Axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.common["Authorization"] = `Bearer ${_token}`;
  return config;
});

Axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  error => {
    let code = error.response.status;
    switch (code) {
      case 401:
        return Promise.resolve({
          data: {
            success: false,
            error: {
              code: code,
              message: "Usuário logado não possui permissão"
            },
            messages: [
              { index: 0, message: "Usuário logado não possui permissão" }
            ]
          }
        });
      default:
        return Promise.reject(error);
    }
  }
);
