import React from "react";
import { Form, InputGroup, Spinner, ButtonProps, Button } from "react-bootstrap";
import Axios from "axios";
import { IResponse } from "../../IResponse";
import { IServerSidePagination } from "../../grid";
import { ICommonInputProps, REQUIRED_MESSAGE } from "../base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './style.scss'

export interface IBaseSelectProps extends ICommonInputProps {
    onChange?(event: any): Promise<void> | void | undefined,
    onBlur?(event: any): Promise<void> | void | undefined,
    onClick?(event: any): Promise<void> | void | undefined,
    value?: any,
    options?: IOptions[],
    endpoint?: string,
    valuePropertyName?: string,
    labelPropertyName?: string,
    loading?: boolean,
    type: 'string' | 'number',
    autoComplete?: boolean,
    inputGroup?: {
        prepend?: {
            icon: any,
            buttonProps: ButtonProps,
            onClick?(event: any): Promise<void> | void | undefined
        },
        append?: {
            icon: any,
            buttonProps: ButtonProps,
            onClick?(event: any): Promise<void> | void | undefined
        }
    },
}

interface IOptions {
    value: any,
    label?: any
}

interface IBaseSelectState {
    value: any,
    loading: boolean,
    options?: IOptions[],
    disabled?: boolean,
    readOnly?: boolean,
    hidden?: boolean,
    isValid?: boolean,
    isInvalid?: boolean
}

class BaseSelect extends React.Component<IBaseSelectProps, IBaseSelectState> {
    static defaultProps: Partial<IBaseSelectProps>;

    state: IBaseSelectState = {
        value: '',
        loading: false,
        options: this.props.options,
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    static getDerivedStateFromProps(props: IBaseSelectProps, state: IBaseSelectState) {
        if (!props.endpoint && props.loading != state.loading)
            return { loading: props.loading };
        
        return null;
    }

    async componentDidMount() {
        const { endpoint, valuePropertyName, labelPropertyName, value } = this.props;

        if (endpoint) {
            this.setState({ loading: true }); 

            const gridParams: IServerSidePagination = {
                page: 0,
                itemsPerPage: 0,
                filter: [],
                sorter: []
            };
            
            let result = await Axios.get(`${endpoint}?gridParams=${JSON.stringify(gridParams)}&userId=0`),
                response = result.data as IResponse;

            const { success, data } = response;

            if (success)
                this.setState({
                    options: data.map(value => ({ value: value[valuePropertyName!], label: value[labelPropertyName!] })),
                    loading: false
                });
            else
                this.setState({
                    loading: false
                });
        }

        this.setState((state) => ({
            options: state.options ? 
                        [{ value: '', label: ''}, ...state.options] : 
                        undefined,
            value: value ? value : state.value
        }));
    }

    onChange = (event: any) => {
        //event.target.options[event.target.selectedIndex].text -- raw value
        let value = event.target.value;

        this.setValue(value);
        if(this.props.onChange) this.props.onChange(event);
    }

    onBlur = (event: any) => {
        let value = event.target.value;

        if (this.props.required && value) {
            this.setState({
                isInvalid: false
            });
        }

        if (this.props.onBlur) this.props.onBlur(event);
    }

    reset = () => {
        this.setState({
            value: this.props.value || '',
            isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
            isValid: this.props.isValid ? this.props.isValid : false,
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            hidden: this.props.hidden ? this.props.hidden : false
        });
    }
    setValue = (value: any) => this.setState({ value });
    getValue = () => this.state.value;

    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });

    checkIfHasInvalidFeedback = () => {
        const { props, state} = this;

        if (!state.isInvalid) return null;

        let feedback = '';
        if (props.required && !state.value)
            feedback = REQUIRED_MESSAGE;
        else if (props.feedback && props.feedback.invalid)
            feedback = props.feedback.invalid;
        else 
            return null;
       
        return <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>;
    }

    checkPrependInputGroup = () => {
        const { inputGroup } = this.props;

        if (this.state.readOnly || this.state.disabled) return;

        if (inputGroup && inputGroup.prepend)
            return (<InputGroup.Prepend className="custom-input mr-0">
                        <Button 
                            size="sm" 
                            onClick={inputGroup.prepend.onClick} 
                            variant={inputGroup.prepend.buttonProps.variant}
                            disabled={inputGroup.prepend.buttonProps.disabled}>
                                <FontAwesomeIcon icon={inputGroup.prepend.icon} />
                        </Button>
                    </InputGroup.Prepend>);
    }

    checkAppendInputGroup = () => {
        const { inputGroup } = this.props;

        if (this.state.readOnly || this.state.disabled) return;

        if (inputGroup && inputGroup.append)
            return (<InputGroup.Append className="custom-input ml-0">
                        <Button 
                            size="sm"
                            onClick={inputGroup.append.onClick} 
                            variant={inputGroup.append.buttonProps.variant}
                            disabled={inputGroup.append.buttonProps.disabled}>
                                <FontAwesomeIcon icon={inputGroup.append.icon} />
                        </Button>
                    </InputGroup.Append>);
    }

    render() {
        const { props, state, onBlur, onChange, checkIfHasInvalidFeedback } = this;

        return <>
                    {state.loading && 
                        <Spinner
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            size="sm"
                            variant="primary"
                            className="spinner-select"
                            hidden={!state.loading}
                        />
                    }
                    <Form.Group hidden={state.hidden} className="custom-selec">
                        <Form.Label 
                            htmlFor={props.id}>
                                {props.label} 
                                {props.required && 
                                    <span className="text-danger">&nbsp;
                                    <strong>*</strong>
                                </span>}
                        </Form.Label>
                        <InputGroup>
                            {this.checkPrependInputGroup()}

                            <Form.Control
                                as="select" 
                                size="sm"
                                bsPrefix="custom-select"

                                readOnly={state.readOnly}
                                disabled={state.disabled}
                                isValid={state.isValid}
                                isInvalid={state.isInvalid}
                                value={state.value} 

                                id={props.id}
                                name={props.name}
                                type={props.type}
                                required={props.required}
                                autoComplete={props.autoComplete}
                            
                                onChange={onChange}
                                onBlur={onBlur}
                                onClick={props.onClick}
                            >
                                {state.options && state.options.map((value, i) =>
                                    <option key={i} value={value.value}>{value.value && value.label ? `${value.label} [${value.value}]` : value.value}</option>
                                )}
                            </Form.Control>
                            {this.checkAppendInputGroup()}

                            {checkIfHasInvalidFeedback()}

                            {props.feedback && props.feedback.valid && 
                            <Form.Control.Feedback type="valid">{props.feedback.valid}</Form.Control.Feedback>}
                        </InputGroup>
                    </Form.Group>
                </>
    }
}

BaseSelect.defaultProps = {
    loading: false,
    valuePropertyName: 'id',
    labelPropertyName: 'description',
    type: 'number'
}

export default BaseSelect;