import BaseService from './base-service'

const baseEndpoint: string = window.serviceUrl;
const baseService = new BaseService();
baseService.setBaseEndpoint(baseEndpoint);

class ZipCodeService {
    static setBaseEndpoint = (endpoint: string) => {
        baseService.setBaseEndpoint(endpoint);
    }
    
    static get = (zipCode: string) => {
        return baseService.get(`/common/api/v1/Address/${zipCode}`);
    }

    static getAddressType = () => {
        return baseService.get('/common/api/v1/AddressType')
    }
}

export default ZipCodeService;