import { FilterOperator } from "../../grid";

// import React, { FunctionComponent } from "react";
// import { Form, Spinner, InputGroup, Button, ButtonProps, Dropdown, Container } from 'react-bootstrap';
// import MaskedInput from 'react-text-mask'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import './style.scss';
// import { isDate } from "util";
// import Util from "../../util";
// import Axios from "axios";
// import { IServerSidePagination } from "../../grid";
// import { IResponse } from "../../IResponse";
// import { faChevronDown, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";



export interface ICommonInputProps {
    id?: string,
    name: string,
    label: string,
    defaultValue?: any,
    mapping?: string,
    readOnly?: boolean,
    disabled?: boolean,
    hidden?: boolean,
    loading?: boolean,
    isValid?: boolean,
    isInvalid?: boolean,
    required?: boolean,
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void),
    filterOperator?: FilterOperator,
    feedback?: {
        invalid: string,
        valid?: string
    }
}

export interface ICommonInputState {
    disabled: boolean,
    readOnly: boolean,
    hidden: boolean,
    isValid: boolean,
    isInvalid: boolean
}

// export interface IBaseInputProps {
//     id?: string,
//     name: string,
//     label: any,
//     type: string,
//     as?: "input" | "select" | "textarea" | "checkbox" | "radio"
//     options?: Array<{
//         key: any, 
//         value?: any
//     }>,
//     showAllOption?: boolean,
//     feedback?: {
//         invalid: string,
//         valid?: string
//     }
//     value?: any | undefined,
//     mask?: Array<any> | ((value: string) => Array<any>) | undefined,
//     hidden?: boolean,
//     readOnly?: boolean,
//     disabled?: boolean,
//     required?: boolean,
//     maxLength?: number,
//     step?: number,
//     onBlur?(event: any): Promise<void> | void | undefined,
//     onChange?(event: any): Promise<void> | void | undefined,
//     onClick?(event: any): Promise<void> | void | undefined,
//     isValid?: boolean,
//     isInvalid?: boolean,
//     loading?: boolean,
//     notAllowSpecialCharacters?: boolean,
//     transformCase?: "lower" | "upper",
//     inputGroup?: {
//         prepend?: {
//             icon: any,
//             buttonProps: ButtonProps,
//             onClick?(event: any): Promise<void> | void | undefined
//         },
//         append?: {
//             icon: any,
//             buttonProps: ButtonProps,
//             onClick?(event: any): Promise<void> | void | undefined
//         }
//     },
//     autoComplete?: string,
//     identifier?: boolean,
//     isDate?: boolean,
//     plaintext?: boolean,
//     serverSide?: {
//         endpoint: string,
//         keyPropertyName?: string,
//         valuePropertyName?: string
//     }
// }

// interface IBaseInputState {
//     value: any,
//     rawValue: any,
//     checked: boolean,
//     hidden?: boolean,
//     readOnly?: boolean,
//     disabled?: boolean,
//     isInvalid?: boolean,
//     isValid?: boolean,
//     options?: Array<{
//         key: any, 
//         value?: any
//     }>,
//     loading?: boolean
// }

export const REQUIRED_MESSAGE: string = "Preenchimento obrigatório";

// export default class BaseInput extends React.Component<IBaseInputProps, IBaseInputState> {
    
//     state: IBaseInputState = {
//         value: this.props.value || '',
//         rawValue: this.props.value || '',
//         checked: this.props.value || false,
//         disabled: this.props.disabled,
//         hidden: this.props.hidden,
//         readOnly: this.props.readOnly,
//         isInvalid: this.props.isInvalid,
//         isValid: this.props.isValid,
//         options: this.props.options,
//         loading: this.props.loading
//     }

//     static getDerivedStateFromProps(props: IBaseInputProps, state: IBaseInputState) {
//         if (!props.serverSide && props.loading != state.loading)
//             return { loading: props.loading };
        
//         return null;
//     }

//     async componentDidMount() {
//         if (this.props.serverSide) {
//             this.setState({
//                 loading: true
//             }); 
//             const gridParams: IServerSidePagination = {
//                 page: 0,
//                 itemsPerPage: 0,
//                 filter: [],
//                 sorter: []
//             };
            
//             let result = await Axios.get(`${this.props.serverSide.endpoint}?gridParams=${JSON.stringify(gridParams)}&userId=0`),
//                 response = result.data as IResponse;

//             if (result.status === 200 && response.success)
//                 this.setState({
//                     options: response.data.map(value => ({ key: value[this.props.serverSide!.keyPropertyName || 'id'], value: value[this.props.serverSide!.valuePropertyName || 'description'] })),
//                     loading: false
//                 });
//         }

//         this.setState((state) => ({
//             options: state.options ? [{ key: '', value: ''}, ...state.options] : undefined
//         }));
//     }

//     handleChange = (event: any) => {
//         let value = event.target.value,
//             rawValue = this.props.as && this.props.as === "select" ? 
//                 event.target.options[event.target.selectedIndex].text : 
//                 value,
//             checked = this.props.as && this.props.as === "checkbox" ?
//                 event.target.checked : false;

//         const { onChange, transformCase, notAllowSpecialCharacters } = this.props;

//         if (notAllowSpecialCharacters) value = value.replace(/[^\w ]+/g, '');

//         if (transformCase) {
//             if (transformCase === 'upper') value = value.toUpperCase();
//             else if (transformCase === 'lower') value = value.toLowerCase();
//         }

//         this.setValue(value, rawValue, checked);
//         if (onChange) onChange(event);
//     }

//     handleBlur = (event: any) => {
//         let value = event.target.value;

//         if (this.props.required && value) {
//             this.setState({
//                 isInvalid: false
//             });
//         }

//         if (this.props.onBlur) this.props.onBlur(event);
//     }

//     setDisabled = (disabled: boolean) => {
//         this.setState({
//             disabled
//         });
//     }

//     setReadOnly = (readOnly: boolean) => {
//         this.setState({
//             readOnly
//         });
//     }

//     setHidden = (hidden: boolean) => {
//         this.setState({
//             hidden
//         });
//     }

//     setValue = (value: any, rawValue: any = this.state.rawValue, checked: boolean = false) => {
//         if (this.props.as && this.props.as === "checkbox")
//             checked = value;

//         this.setState({
//             value,
//             rawValue,
//             checked
//         });
//     }

//     setIsInvalid = (isInvalid: boolean) => {
//         this.setState({
//             isInvalid,
//             isValid: false
//         });
//     }

//     setIsValid = (isValid: boolean) => {
//         this.setState({
//             isValid,
//             isInvalid: false
//         });
//     }

//     reset = () => {
//         this.setState({
//             value: '',
//             isInvalid: false,
//             isValid: false
//         });
//     }

//     checkPrependInputGroup = () => {
//         const { inputGroup } = this.props;

//         if (this.state.readOnly || this.state.disabled) return;

//         if (inputGroup && inputGroup.prepend)
//             return (<InputGroup.Prepend className="custom-input mr-0">
//                         <Button 
//                             size="sm" 
//                             onClick={inputGroup.prepend.onClick} 
//                             variant={inputGroup.prepend.buttonProps.variant}
//                             disabled={inputGroup.prepend.buttonProps.disabled}>
//                                 <FontAwesomeIcon icon={inputGroup.prepend.icon} />
//                         </Button>
//                     </InputGroup.Prepend>);
//     }

//     checkAppendInputGroup = () => {
//         const { inputGroup } = this.props;

//         if (this.state.readOnly || this.state.disabled) return;

//         if (inputGroup && inputGroup.append)
//             return (<InputGroup.Append className="custom-input ml-0">
//                         <Button 
//                             size="sm"
//                             onClick={inputGroup.append.onClick} 
//                             variant={inputGroup.append.buttonProps.variant}
//                             disabled={inputGroup.append.buttonProps.disabled}>
//                                 <FontAwesomeIcon icon={inputGroup.append.icon} />
//                         </Button>
//                     </InputGroup.Append>);
//     }

//     getValue = (): any => {
//         return isDate(this.state.value) ? 
//                 Util.dateISOToDate(this.state.value) : 
//                 this.state.value;
//     }

//     baseMaskedInput = () => {
//         return <MaskedInput
//                     id={this.props.id}
//                     name={this.props.name}
//                     type={this.props.type}
//                     readOnly={this.state.readOnly}
//                     disabled={this.state.disabled}
//                     required={this.props.required}
//                     maxLength={this.props.maxLength}
//                     step={this.props.step}
//                     className={`form-control form-control-sm ${this.state.isValid ? 'is-valid': this.state.isInvalid ? 'is-invalid' : ''}`}
//                     mask={this.props.mask}
//                     guide={false}
//                     value={this.getValue()}
//                     defaultValue={this.props.value}
//                     onChange={this.handleChange}
//                     onBlur={this.handleBlur}
//                     onClick={this.props.onClick}
//                     autoComplete={this.props.autoComplete}
//                 />;
//     }

//     baseInput = () => {
//         const { as, maxLength } = this.props;

//         const dynamicProps: any = {};
//         if (as) dynamicProps.as = as;
//         if (as === "input") dynamicProps.maxLength = maxLength;

//         return <Form.Control 
//                     id={this.props.id}
//                     as={this.props.as}
//                     name={this.props.name}
//                     type={this.props.type}
//                     readOnly={this.state.readOnly}
//                     disabled={this.state.disabled}
//                     required={this.props.required}
//                     maxLength={this.props.maxLength}
//                     step={this.props.step}
//                     value={this.getValue()}
//                     defaultValue={this.props.value}
//                     onChange={this.handleChange}
//                     onBlur={this.handleBlur}
//                     onClick={this.props.onClick}
//                     size="sm"
//                     isValid={this.state.isValid}
//                     isInvalid={this.state.isInvalid}
//                     autoComplete={this.props.autoComplete}
//                     plaintext={this.props.plaintext}
//                     {...dynamicProps}
//                 >
//                     {this.state.options && this.state.options.map((value, i) =>
//                         <option key={i} value={value.key}>{value.key && value.value ? `${value.value} [${value.key}]` : value.key}</option>
//                     )}
//                 </Form.Control>;
//     }

//     getInput = () => {
//         if (this.props.mask) return this.baseMaskedInput();
//         return this.baseInput();
//     }

//     render() {
//         return (
//             <>
//                 <Spinner
//                     animation="border"
//                     role="status"
//                     aria-hidden="true"
//                     size="sm"
//                     variant="primary"
//                     className="spinner"
//                     hidden={!this.state.loading}
//                 />
//                 <Form.Group hidden={this.state.hidden}>
//                     <Form.Label 
//                         htmlFor={this.props.id}>
//                             {this.props.label} 
//                             {this.props.required && 
//                                 <span className="text-danger">&nbsp;
//                                 <strong>*</strong>
//                             </span>}
//                     </Form.Label>
//                     <InputGroup>
//                         {this.checkPrependInputGroup()}
//                         {this.getInput()}
//                         {this.checkAppendInputGroup()}

//                         {this.props.required && !this.state.value && this.state.isInvalid &&
//                         <Form.Control.Feedback type="invalid">{REQUIRED_MESSAGE}</Form.Control.Feedback>}

//                         {this.props.feedback && this.props.feedback.invalid && this.state.isInvalid && this.state.value &&
//                         <Form.Control.Feedback type="invalid">{this.props.feedback.invalid}</Form.Control.Feedback>}

//                         {this.props.feedback && this.props.feedback.valid && 
//                         <Form.Control.Feedback type="valid">{this.props.feedback.valid}</Form.Control.Feedback>}
//                     </InputGroup>

//                     {this.state.value && this.props.maxLength && this.props.maxLength > 1 && this.getValue().length > 0 && 
//                         <Form.Text className="text-muted text-right">
//                             {this.getValue().length + " / " + this.props.maxLength} 
//                         </Form.Text>
//                     }
//                 </Form.Group>
//             </>
//         );
//     }
// }