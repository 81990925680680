import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faPlus, faHistory, faSearch, faCloudUploadAlt, faTrash, faEllipsisH, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col, Badge, Dropdown } from 'react-bootstrap';

import BaseModal, { TypesBaseModal, IBaseModalProps } from '../modal';
import BaseLog from '../log';
import BaseExport, { ExportTypes } from '../export';

import './style.scss';
import { IFilter, IServerSidePagination } from '../grid';
import UserContext from '../context/user';
import BaseForm from '../form';

export interface ICrud {
    form?: any,
    create?: {
        show: boolean,
        permissionId?: string,
        endpoint?: string
    },
    read?: {
        show: boolean
    },
    update?: {
        show: boolean,
        permissionId?: string,
        endpoint?: string
    },
    delete?: {
        show: boolean,
        identifiersPropertyName?: string[],
        permissionId?: string,
        endpoint?: string
    },
    log?: {
        show: boolean,
        id: number,
        keys: string
    },
    exportGrid?: {
        show: boolean,
        types?: Array<ExportTypes>,
        permissionId?: string
    },
    upload?: {
        show: boolean,
        permissionId?: string
    }
}

export interface IBaseToolbarProps {
    crud: ICrud,
    search: {
        show: boolean,
        form: any,
        endpoint?: string
    },
    selectedItems: any[],
    identifiers: string[],
    data: Array<any>,
    name: string,
    filter?: IFilter[],
    callbackFilter?(filter: IFilter[]): Promise<void> | void,
    callbackRemoveSelectedItems?(): void,
    baseEndpoint?: string,
    gridParams?: IServerSidePagination,
    dependentProperty?: {
        name: string,
        value: any
    }
}

interface IBaseToolbarState {
    hideLoading: boolean,
    crud?: ICrud,
    modal: IBaseModalProps
}

export default class BaseToolbar extends React.Component<IBaseToolbarProps, IBaseToolbarState> {
    context!: React.ContextType<typeof UserContext>;
    private baseModalRef = React.createRef<BaseModal>();
    static defaultProps: IBaseToolbarProps;

    state: IBaseToolbarState = {
        hideLoading: true,
        modal: {
            show: false,
            type: TypesBaseModal.read,
            content: this.props.crud.form,
            callbackAfterClose: () => undefined,
            data: this.props.data,
            name: this.props.name
        }
    }

    private showLog = () => { 
        const { id, keys } = this.props.crud.log!;
        const content = <BaseLog 
                            name={this.props.name}
                            id={id}
                            keys={keys}
                            data={this.props.data}
                        />;

        this.setState(() => ({
            modal: {
                ...this.state.modal, 
                content,
                show: true,
                size: "xl",
                type: TypesBaseModal.log
            }
        }));
    }    

    private new = () => {
        let form = React.cloneElement(
            this.props.crud.form, 
            { 
                type: TypesBaseModal.create,
                baseModalRef: this.baseModalRef,
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter,
                endpoint: this.formatEndpoint(this.props.crud.create!.endpoint!),
                dependentProperty: this.props.dependentProperty
            }
        );

        this.showModal(TypesBaseModal.create, form, "xl");
    }

    private formatEndpoint = (endpoint: string) : string => {
        if (endpoint)
            return `${this.props.baseEndpoint}/${endpoint}`;
        
        return this.props.baseEndpoint!;
    }

    private edit = () => {
        let form = React.cloneElement(
            this.props.crud.form, 
            { 
                data: this.props.data,
                type: TypesBaseModal.update,
                baseModalRef: this.baseModalRef,
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter,
                endpoint: this.formatEndpoint(this.props.crud.update!.endpoint!),
                dependentProperty: this.props.dependentProperty
            }
        );

        this.showModal(TypesBaseModal.update, form, "xl");
    }

    private delete = () => {
        let form = <BaseForm
                        data={this.props.data}
                        type={TypesBaseModal.delete}
                        baseModalRef={this.baseModalRef}
                        filter={this.props.filter}
                        callbackFilter={this.props.callbackFilter}
                        callbackRemoveSelectedItems={this.props.callbackRemoveSelectedItems}
                        endpoint={this.formatEndpoint(this.props.crud.delete!.endpoint!)}
                        dependentProperty={this.props.dependentProperty}
                        identifiers={this.props.identifiers}
                        refs={[]}
                    />

        this.showModal(TypesBaseModal.delete, form, "sm");   
    }

    private detail = () => {
        let form = React.cloneElement(
            this.props.crud.form, 
            { 
                data: this.props.data,
                type: TypesBaseModal.read,
                baseModalRef: this.baseModalRef
            }
        );

        this.showModal(TypesBaseModal.read, form, "xl");   
    }

    private search = () => {
        let form = React.cloneElement(
            this.props.search.form,
            { 
                type: TypesBaseModal.search, 
                baseModalRef: this.baseModalRef, 
                filter: this.props.filter,
                callbackFilter: this.props.callbackFilter
            }
        );

        this.showModal(TypesBaseModal.search, form, "xl");
    }

    private showModal = (type: TypesBaseModal, content: any, size: "xl" | "sm" | "lg") => {
        this.setState((state) => ({
            modal: {
                ...state.modal,
                content,
                show: true,
                size,
                type                
            }
        }));
    }

    private hideModal = () => {
        this.setState((state) => ({
            modal: {
                ...state.modal, 
                show: false
            }
        }));
    }
    
    showExport = () => {
        const { exportGrid } = this.props.crud;
        
        return exportGrid && exportGrid.show &&
                (!exportGrid.permissionId || exportGrid.permissionId && this.checkIfHasPermission(exportGrid.permissionId));
    }

    showUpdate = () => {
        const { update } = this.props.crud;

        return update && update.show &&
            ((!update.permissionId || update.permissionId && this.checkIfHasPermission(update.permissionId)));
    }

    showCreate = () => {
        const { create } = this.props.crud;

        return create && create.show &&
            ((!create.permissionId  || create.permissionId && this.checkIfHasPermission(create.permissionId)));
    }

    showDelete = () => {
        let deleteForm = this.props.crud.delete;

        return deleteForm && deleteForm.show &&
            ((!deleteForm.permissionId || deleteForm.permissionId && this.checkIfHasPermission(deleteForm.permissionId)));
    }

    checkIfHasPermission = (permissionId: string) => {
        const { checkIfHasPermission, permissionIdList } = this.context;
        return checkIfHasPermission(permissionId, permissionIdList);
    }
    
    render() {
        return (
            <div className="border-bottom toolbar bg-white">
                <BaseModal 
                    content={this.state.modal.content} 
                    size={this.state.modal.size} 
                    type={this.state.modal.type} 
                    show={this.state.modal.show} 
                    callbackAfterClose={this.hideModal}
                    data={this.props.data}
                    ref={this.baseModalRef}
                    name={this.props.name}
                />
                <Row>
                    <Col className="left-toolbar text-left">
                        {this.props.crud.log && this.props.crud.log.show &&
                        <Button type="button" onClick={this.showLog} size="sm" disabled={this.props.selectedItems.length === 0}>
                            <FontAwesomeIcon icon={faHistory} /> Log
                        </Button>}

                        {this.showExport() &&
                        <BaseExport 
                            gridParams={this.props.gridParams} 
                            mode="modal" 
                            selectedItems={this.props.selectedItems} 
                            identifiers={this.props.identifiers}
                            endpoint={this.props.baseEndpoint}
                            userId={this.context.userId}
                        />}

                        {this.props.crud.upload && this.props.crud.upload.show &&
                        <Button type="button" size="sm" variant="success">
                            <FontAwesomeIcon icon={faCloudUploadAlt} /> Upload
                        </Button>}
                    </Col>
                    <Col className="right-toolbar text-right">
                        {this.props.search && this.props.search.show &&
                        <Button type="button" onClick={this.search} size="sm">
                            <FontAwesomeIcon icon={faSearch} /> Pesquisar
                            &nbsp;
                            <Badge variant="secondary">{this.props.filter ? this.props.filter.filter(value => value.closeable !== false).length : 0}</Badge>
                        </Button>}
                        
                        {this.showCreate() &&
                        <Button type="button" onClick={this.new} size="sm" variant="success">
                            <FontAwesomeIcon icon={faPlus} /> Novo
                        </Button>}

                        {this.showUpdate() &&
                        <Button type="button" onClick={this.edit} size="sm" variant="warning" disabled={this.props.selectedItems.length !== 1}>
                            <FontAwesomeIcon icon={faEdit} /> Editar
                        </Button>}
                        
                        {this.showDelete() &&
                        <Button type="button" onClick={this.delete} size="sm" variant="danger" disabled={this.props.selectedItems.length !== 1}>
                            <FontAwesomeIcon icon={faTrash} /> Excluir
                        </Button>}

                        {this.props.crud.read && this.props.crud.read.show &&
                        <Button type="button" onClick={this.detail} size="sm" disabled={this.props.selectedItems.length === 0}>
                            <FontAwesomeIcon icon={faEye} /> Detalhar
                        </Button>}
                        {// adicionar novos botões ao toolbal
                        }
                        {false && <Dropdown drop="down" alignRight className="custom-dropdown">
                            <Dropdown.Toggle variant="light" id={`${this.props.name}-dropdown-toolbar-columns-options-grid`} size="sm" >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1"><FontAwesomeIcon icon={faCreditCard} /> Cartões</Dropdown.Item>
                                <Dropdown.Item eventKey="1"><FontAwesomeIcon icon={faEllipsisH} /> Outros</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </Col>
                </Row>
            </div>
        );
    }
}

BaseToolbar.contextType = UserContext;