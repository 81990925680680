import React, { FunctionComponent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ErrorBoundary from "../error-boundary";
import { UserProvider } from "../context/user";
import Util from "../util";
import AuthService from "./../../services/auth-service";
import PermissionService from "../../services/permission-service";
import Loading from "../loading";

interface IBaseScreenProps {
  title: string;
  description?: string;
}

const BaseScreen: FunctionComponent<IBaseScreenProps> = props => {
  const [permissionIdList, setPermissionIdList] = useState<Array<string>>(),
    userId: number = parseInt(Util.getUrlParams().get("p_id_usuario") || "0"),
    moduleId: number = parseInt(Util.getUrlParams().get("p_id_modulo") || "0"),
    token: string = Util.getUrlParams().get("token") || "";

  useEffect(() => {
    setPermissionIdList([]);
    if (token) AuthService.setAuthToken(token);
    if (moduleId && userId)
      PermissionService.get(userId, moduleId).then(value => {
        const { data, status } = value;

        if (status === 200) setPermissionIdList(data.data);
        else {
          setPermissionIdList([]);
          throw new Error("Erro ao carregar as permissões");
        }
      });
  }, []);

  const render = permissionIdList ? (
    <ErrorBoundary>
      <UserProvider
        value={{
          userId,
          moduleId,
          permissionIdList,
          checkIfHasPermission: Util.checkIfValueExistsInList
        }}
      >
        <Container fluid className="bg-light p-2">
          <h4>
            {props.title} -{" "}
            <small className="text-muted lead">{props.description}</small>
          </h4>
          {props.children}
        </Container>
      </UserProvider>
    </ErrorBoundary>
  ) : (
    <Loading hidden={false} />
  );

  return render;
};

export default BaseScreen;
