import React, { RefObject } from "react";
import BaseInput from "../../input/input";
import { Row, Col } from "react-bootstrap";
import BaseFieldSet from "../../fieldset";
import { BaseSelect } from "../..";

export interface IBankInputGroupProps {
    id:{
        name: string,
        innerRef: RefObject<any>
    },
    bank: {
        name: string,
        innerRef: RefObject<any>
    },
    agency: {
        name: string,
        innerRef: RefObject<any>
    },
    account: {
        name: string,
        innerRef: RefObject<any>
    }
}

class BankInputGroup extends React.Component<IBankInputGroupProps> {
    render() {
        return (
            <BaseFieldSet legend="Banco">
                <Row>
                    <Col xs="12" sm="12" md="7" lg="4" xl="4">
                    <BaseInput
                            {...this.props.id}
                            type="text"
                            label="Id"
                            readOnly
                            identifier
                            ref={this.props.id.innerRef}
                        />
                    </Col>
                
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <BaseSelect 
                            {...this.props.bank}
                            type="number"
                            label="Banco"
                            endpoint={`${window.serviceUrl}/common/api/v1/Bank/Read`}
                            labelPropertyName="name"
                            required
                            ref={this.props.bank.innerRef}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="7" lg="4" xl="4">
                        <BaseInput
                            {...this.props.agency}
                            type="text"
                            label="Agência"
                            required
                            maxLength={15}
                            ref={this.props.agency.innerRef}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="5" lg="2" xl="2">
                        <BaseInput 
                            {...this.props.account}
                            type="text"
                            label="Conta"
                            required
                            maxLength={15}
                            ref={this.props.account.innerRef}
                        />
                    </Col>
                </Row>
            </BaseFieldSet>
        );
    }
}

export default BankInputGroup;