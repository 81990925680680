import { conformToMask } from "react-text-mask";
import { isDate, isObject } from "util";
import { IAddress } from "../input/cep";
import { useState, useEffect } from "react";

const localePTBR = 'pt-BR';

export default class Util {
    static getNestedObject = (nestedObj: any, pathArr: any): any => {
        return pathArr.reduce((obj: any, key: any) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }

    static getObjectValue = (data: any, key: string) => {
        if (data) {
            if (!data.hasOwnProperty(key)) {
                console.warn(`chave [${key}] não localizada na model -->`, data);
                return null;
            }
            else
                return data[key];
        }

        console.warn(`chave [${key}] não localizada. Valor null`);
        return null;
    }

    static getObjectValueFromObject = (data: any, key: string) : any => {
        let keys = key.split('.');
        return Util.getNestedObject(data, keys);
    }

    static openMapWindow = (address: IAddress) => {
        let street = address.streetNumber ? `${address.street}, ${address.streetNumber}` : address.street,
            query = `${street} - ${address.district}, ${address.city} - ${address.state}, ${address.zipCode}`;
            
        window.open(`https://www.google.com/maps/place/${query}`, '_blank')
    }

    static dateToISO = (date: string) => {
        return new Date(date).toISOString();
    }

    static objectsAreTheSame = (x: any, y: any) : boolean => {
        for (let key in x)
            if (x[key] !== y[key])
                return false;
            
        return true;
    }

    static dateISOToDate = (date: string | Date, locale: string = localePTBR) => {
        date = isDate(date) ? date : new Date(date);
        return date.toLocaleDateString(locale);
    }

    static dateISOToTime = (date: string | Date, locale: string = localePTBR) => {
        date = isDate(date) ? date : new Date(date);
        return date.toLocaleTimeString(locale);
    }

    static dateISOToDateTime = (date: string | Date, locale: string = localePTBR) => {
        date = isDate(date) ? date : new Date(date);
        return date.toLocaleString(locale);
    }

    static conformToMask = (value: string, mask: any) => {
        let masked = conformToMask(
            value,
            mask,
            { guide: false }
        );

        return masked.conformedValue;
    }

    static getUrlParams = () => {
        return new URLSearchParams(window.location.search);
    }

    static checkIfValueExistsInList = (value: any, list: Array<any>): boolean => {
        return list ? list.filter(item => item === value).length > 0 : false;
    }

    static onlyNumbers = (value: string) => {
        return value.replace(/[^\d]+/g, '');
    }

    static useDebounceTime(value: any, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

}