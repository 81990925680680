import React from 'react';
import DateInput, { IDateInputProps } from '../date';

import './style.scss';

export interface IDateRangeProps {
    startDate: IDateInputProps,
    endDate: IDateInputProps
}

export default class DateRangeInput extends React.Component<IDateRangeProps> {
    static defaultProps: IDateRangeProps;

    state = {
        startDate: null,
        endDate: null
    }

    setStartDate = (startDate: Date | null) => {
        this.setState(() => ({
            startDate
        }));
    }

    setEndDate = (endDate: Date | null) => {
        this.setState(() => ({
            endDate
        }));
    }

    render() {
        const { startDate, endDate } = this.props;

        return (
            <>
                <DateInput
                    {...startDate}
                    selected={this.state.startDate} 
                    startDate={this.state.startDate} 
                    endDate={this.state.endDate}
                    maxDate={this.state.endDate}
                    selectsStart 
                    callbackChange={(date) => this.setStartDate(date)} 
                />
                <DateInput
                    {...endDate}
                    selected={this.state.endDate} 
                    startDate={this.state.startDate} 
                    endDate={this.state.endDate} 
                    minDate={this.state.startDate}
                    selectsEnd 
                    callbackChange={(date) => this.setEndDate(date)}
                />
            </>
        );
    }
}

DateRangeInput.defaultProps = {
    startDate: {
        label: "Data início",
        name: "startDate",
        id: "uvaaa"
    },
    endDate: {
        label: "Data fim",
        name: "endDate",
        id: "maça"
    }
}