import React from 'react'
import { ButtonToolbar, Button, Dropdown, Form, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import BaseModal, { TypesBaseModal } from '../modal'
import BaseInput from '../input/input'
import BaseSelect from '../input/select'

import './style.scss'
import { IServerSidePagination, FilterOperator } from '../grid'
import  axios from 'axios';

export enum ExportTypes {
    //xls = "xls", 
    //xlsx = "xlsx", 
    csv = "csv", 
    txt = "txt", 
    //pdf = "pdf", 
    json = "json", 
    xml = "xml"
}

export interface IBaseExportProps {
    mode: "dropdown" | "modal",
    types: Array<ExportTypes>,
    defaultName: string,
    defaultDelimiter: string,
    hidden: boolean,
    selectedItems: any[],
    identifiers: string[],
    name: string,
    gridParams: IServerSidePagination
    endpoint: string,
    userId: number
}

enum ExportFormats {
    selected = 0,
    currentPage = 1,
    all = 2
}

interface IBaseExportState {
    type: ExportTypes,
    name: string,
    delimiter: string,
    showModal: boolean
}

export default class BaseExport extends React.Component<IBaseExportProps, IBaseExportState> {
    static defaultProps: IBaseExportProps;

    private typeRef = React.createRef<any>();
    private nameRef = React.createRef<any>();
    private delimiterRef = React.createRef<any>();

    private inputRefs = [
        this.typeRef, this.nameRef, this.delimiterRef
    ];

    state = {
        type: ExportTypes.csv,
        name: this.props.defaultName,
        delimiter: this.props.defaultDelimiter,
        showModal: false
    }

    showExport = () => {
        this.setState({
            showModal: true
        });
    }

    hideExport = () => {
        this.setState({
            showModal: false
        });
    }

    checkIfExportTypeHasDelimiter = (exportType: ExportTypes) => {
        return exportType === ExportTypes.csv || exportType === ExportTypes.txt ? true : false;
    }

    checkHasInvalidInput = () => {
        let result = false;
        this.inputRefs.forEach((value) => {
            if (value.current) {
                let invalid = value.current.state.value === '';
                value.current.setIsInvalid(invalid);

                if (invalid)
                    result = true;
            }
        });

        return result;
    }

    handleChange = (event: any) => {
        const value = event.target.value,
               name = event.target.name;
        
        this.setState({
            [name]: value
        } as Pick<IBaseExportState, keyof { name: string, type: string, delimiter: string }>);
    }

    handleExport = async (exportFormat: ExportFormats) => {
        const { name, delimiter, type } = this.state;

        let gridParams = this.props.gridParams

        if (exportFormat === ExportFormats.selected) {
            let filter = this.props.identifiers.map(id => (
                { 
                    property: id, 
                    value: this.props.selectedItems.map(value => value[id]),
                    operator: FilterOperator.Equals
                }
            ));

            gridParams = {
                ...gridParams, 
                filter,
                page: 0, 
                itemsPerPage: 0 
            };
        }
        else if (exportFormat === ExportFormats.all)
            gridParams = { ...gridParams, page: 0, itemsPerPage: 0 };

        if (!this.checkHasInvalidInput())
            axios.get(`${this.props.endpoint}/Report?gridParams=${JSON.stringify(gridParams)}&userId=${this.props.userId}&filename=${name}&delimiter=${delimiter}&format=${type}`,{
                headers:{
                    'Content-Type': `application/${type}`
                }
            })
            .then((response) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                
                var blob = new Blob(["\ufeff", response.data], {
                    type: `application/${type}`
                });
                 
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = name + "." + type;
                a.click();
                window.URL.revokeObjectURL(url);
                //window.open(url);
                
            });

            //window.open(`${this.props.endpoint}/Report?gridParams=${JSON.stringify(gridParams)}&userId=${this.props.userId}&filename=${name}&delimiter=${delimiter}&format=${type}`);
    }

    private exportForm = () => {
        const { type, name, delimiter } = this.state;
        return <>
            <p className="text-danger text-right m-0 p-0">&nbsp;
                <strong>*</strong>Obrigatório
            </p>
            <Form noValidate id={`${this.props.name}-form`}>
                <BaseSelect
                    type="string"
                    label="Tipo"           
                    name="type" 
                    id={`${this.props.name}-form-exportType`}
                    onChange={this.handleChange}
                    value={type}
                    required
                    options={this.props.types.map(value => ({ value: value }))}
                    ref={this.typeRef}
                />

                <BaseInput 
                    label="Nome"
                    type="text"        
                    maxLength={30}         
                    name="name" 
                    id={`${this.props.name}-form-export-name`}
                    onChange={this.handleChange}
                    value={name}
                    required
                    ref={this.nameRef}
                />

                {this.checkIfExportTypeHasDelimiter(type) &&
                    <BaseInput 
                        label="Delimitador"
                        type="text"        
                        maxLength={1}         
                        name="delimiter" 
                        id={`${this.props.name}-form-exportDelimiter`}
                        onChange={this.handleChange}
                        value={delimiter}
                        required
                        ref={this.delimiterRef}
                    />
                }
            </Form>
        </>;
    }

    iconAndNameExport = () => {
        return <>
            <FontAwesomeIcon icon={faCloudDownloadAlt} /> Exportar
        </>;
    }

    dropDownMode = () => {
        const { name, selectedItems } = this.props;
        const id = 'dropdown-btn';

        return <>
            <Dropdown className="customDropDown">
                <Dropdown.Toggle id={`${name}-${id}-export`} size="sm">
                    {this.iconAndNameExport()}
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    <Container>
                        {this.exportForm()}
                    </Container>
                    
                    <Dropdown.Divider />
                    <Dropdown.Item id={`${name}-${id}-selected`} onClick={() => this.handleExport(ExportFormats.selected)} disabled={selectedItems.length === 0}>Selecionados</Dropdown.Item>
                    <Dropdown.Item id={`${name}-${id}-current-page`} onClick={() => this.handleExport(ExportFormats.currentPage)}>Página atual</Dropdown.Item>
                    <Dropdown.Item id={`${name}-${id}-all`} onClick={() => this.handleExport(ExportFormats.all)}>Todos</Dropdown.Item>
                </Dropdown.Menu>

            </Dropdown>
        </>;
    }

    modalMode = () => {
        const { name, selectedItems } = this.props;
        const id = 'export-modal-btn';

        return <>
            <Button type="button" onClick={this.showExport} size="sm">
                {this.iconAndNameExport()}
            </Button>
            <BaseModal 
                content={
                    <>
                        {this.exportForm()}
                        <ButtonToolbar>
                            <Button id={`${name}-${id}-selected`} variant="primary" size="sm" onClick={() => this.handleExport(ExportFormats.selected)} disabled={selectedItems.length === 0}>Selecionados</Button>&nbsp;
                            <Button id={`${name}-${id}-current-page`} variant="primary" size="sm" onClick={() => this.handleExport(ExportFormats.currentPage)}>Página atual</Button>&nbsp;
                            <Button id={`${name}-${id}-all`} variant="primary" size="sm" onClick={() => this.handleExport(ExportFormats.all)}>Todos</Button>
                        </ButtonToolbar>
                    </>
                } 
                size='sm'
                type={TypesBaseModal.export} 
                show={this.state.showModal}
                callbackAfterClose={this.hideExport}
                name={this.props.name}
            />
        </>;
    }

    render() {
        return (
            !this.props.hidden && (
                (this.props.mode === 'dropdown' && this.dropDownMode()) ||
                (this.props.mode === 'modal' && this.modalMode())
            )
        );
    }
}

BaseExport.defaultProps = {
    mode: 'dropdown',
    types: [
        ExportTypes.csv, 
        ExportTypes.txt, 
        ExportTypes.json, 
        ExportTypes.xml
    ],
    defaultName: 'download',
    defaultDelimiter: ';',
    hidden: false,
    selectedItems: [],
    identifiers: [],
    name: 'export',
    gridParams: {
        itemsPerPage: 10,
        page: 1,
        filter: [],
        sorter: []
    },
    endpoint: '',
    userId: 0
}