import React from 'react'

interface IContextPermissionProps {
    userId: number,
    moduleId: number,
    permissionIdList: Array<string>
    checkIfHasPermission(permissionId: string, permissionIdList: Array<string>): boolean | undefined
}

const UserContext = React.createContext<IContextPermissionProps>({
    moduleId: 0,
    userId: 0,
    permissionIdList: [],
    checkIfHasPermission: () => undefined
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext