import React, { RefObject } from "react";
import BaseInput, { IBaseInputProps } from '../input';
import ZipCodeService from "../../../services/zipcode-service";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Util from "../../util";

export const CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export interface IAddress {
    zipCode: string,
    street: string,
    streetNumber?: string,
    complement?: string,
    district: string,
    city: string,
    state: string,
}

export interface ICepInputProps extends IBaseInputProps {
    showMapsButton?: boolean,
    callbackAddress?(address: IAddress): Promise<void> | void | undefined,
    innerRef: RefObject<any>
}

export const MapsWindowButton = (props: { label: string, address: IAddress }) => {
    const { address, label } = props;

    return <>
        {label}
        {checkAddress(address) &&
        <Button size="sm" onClick={() => Util.openMapWindow(address)} variant="link">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
        </Button>}
    </>;
}

export const checkAddress = (address: IAddress) : boolean => {
    return address.street && address.city && address.state && address.zipCode ? true : false;
}

export default class CepInput extends React.Component<ICepInputProps> {
    static defaultProps: IBaseInputProps;

    state = {
        loading: false,
        address: {} as IAddress
    }

    onBlur = async (event: any) => {
        let value = event.target.value.replace(/[^\d]+/g, '');

        this.setState({
            loading: true
        });

        let isValid = await this.checkIfIsValid(value);

        if (this.props.innerRef.current) {
            this.props.innerRef.current.setValue(value);
            
            if (!isValid)
                this.props.innerRef.current.setIsInvalid(true);
            else
                this.props.innerRef.current.setIsInvalid(false);
        }

        this.setState({
            loading: false,
            address: isValid ? this.state.address : {}
        }, () => {
            if(this.props.callbackAddress) this.props.callbackAddress(this.state.address);
        });
    }

    checkIfIsValid = async (value: any) => {
        if (!value || value.length < 8) return false;

        let result = await ZipCodeService.get(value);
        if (!result.data.success) return false;

        let address: IAddress = {
            zipCode: value,
            street: result.data.data.addressLine, 
            district: result.data.data.neighborhood, 
            city: result.data.data.city.id,
            state: result.data.data.city.state.id
        };

        this.setState({ address });

        return true;
    }

    render() {
        const { showMapsButton } = this.props;

        let dynamicProps: any = {};

        if (showMapsButton && checkAddress(this.state.address)) {
            dynamicProps.inputGroup = {
                append: {
                    buttonProps: {
                        variant: "secondary"
                    },
                    icon: faMapMarkerAlt,
                    onClick: () => Util.openMapWindow(this.state.address)
                }
            };
        }

        return (
            <BaseInput
                {...this.props}
                type="text"
                label="CEP"
                feedback={{
                    invalid: "Informe um CEP válido"
                }}                
                mask={CEP_MASK}
                onBlur={this.onBlur}
                value={this.props.value}
                loading={this.state.loading}
                ref={this.props.innerRef}
                {...dynamicProps}
            />
        );
    }
}   